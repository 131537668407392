import React from 'react'
import { NavLink, NavSectionHeader, PageContainer } from '@flock/shared-ui'
import { Box, Grid } from '@mui/material'
import { navigate } from 'gatsby'
import { useCalendlyEventListener } from 'react-calendly'
import {
  ABOUT_US_PATH,
  CALCULATOR_PATH,
  FAQ_PATH,
  HOMEPAGE_PATH,
  HOW_721_WORKS_PATH,
  HOW_TO_SIGN_UP_PATH,
  ONBOARDING_PATH,
  PORTFOLIO_PATH,
} from '../../routeConstants'
import { track } from '../../utils/analytics'
import SectionLayout from './SectionLayout'
import OmNavbar from './OmNavbar/OmNavbar'
import Footer from './Footer/Footer'
import Disclaimer from './Disclaimer'
import { INVESTOR_URL } from '../../constants'

// Utility method to create a nav function
const navTo = (path: string) => () => {
  navigate(path)
}

const navItems = [
  {
    label: 'Learn',
    dropdownContent: (
      <Grid container spacing={1}>
        <Grid item xs={12} gridAutoColumns="auto-fit">
          <NavLink to={HOW_721_WORKS_PATH} color="mulberry.main">
            How 721 exchanges work
          </NavLink>
          <NavLink to={HOW_TO_SIGN_UP_PATH} color="mulberry.main">
            How to sign up
          </NavLink>
          <NavLink to={CALCULATOR_PATH} color="mulberry.main">
            Rent vs Sell vs 721 Calculator
          </NavLink>
          <NavLink to={FAQ_PATH} color="mulberry.main">
            Frequently asked questions (FAQ)
          </NavLink>
        </Grid>
      </Grid>
    ),
  },
  {
    label: 'About',
    to: ABOUT_US_PATH,
  },
  {
    label: 'Portfolio',
    to: PORTFOLIO_PATH,
  },
]

const mobileNavItems = (
  <>
    <NavSectionHeader color="#ffffff50">Learn</NavSectionHeader>
    <NavLink color="white.main" to={HOW_721_WORKS_PATH}>
      How 721 exchanges work
    </NavLink>
    <NavLink color="white.main" to={HOW_TO_SIGN_UP_PATH}>
      How to sign up
    </NavLink>
    <NavLink color="white.main" to={CALCULATOR_PATH}>
      Rent vs Sell vs 721 Calculator
    </NavLink>
    <NavLink color="white.main" to={FAQ_PATH}>
      FAQ
    </NavLink>
    <NavSectionHeader color="#ffffff50">About</NavSectionHeader>
    <NavLink color="white.main" to={ABOUT_US_PATH}>
      About Overmoon
    </NavLink>
    <NavSectionHeader color="#ffffff50">Investments</NavSectionHeader>
    <NavLink color="white.main" to={PORTFOLIO_PATH}>
      Portfolio
    </NavLink>
  </>
)

const actions = [
  {
    label: 'Request Offer',
    variant: 'primary',
    onClick: navTo(`${ONBOARDING_PATH}`),
  },
  {
    label: 'Sign in',
    variant: 'secondaryDark',
    onClick: () => {
      window.location.href = INVESTOR_URL
    },
  },
]

type PageWrapperProps = {
  title: string
  trackingName?: string

  children: React.ReactNode
  metaTags?: any[]
}

const PageWrapper = (props: PageWrapperProps) => {
  const { title, trackingName, children, metaTags } = props

  const isSSR = typeof window === 'undefined'
  useCalendlyEventListener({
    onEventScheduled: () => {
      track('scheduledSalesCall')
    },
  })
  return (
    <PageContainer
      title={title}
      trackingName={trackingName}
      metaTags={metaTags}
    >
      <Box id="calendlyPopupRoot" height="auto">
        <OmNavbar
          logoPath={HOMEPAGE_PATH}
          navItems={navItems}
          actions={actions}
          mobileNavItems={mobileNavItems}
        />
        {children}
        <SectionLayout name="disclaimer" verticalPadding="30px">
          <Grid item xs={12}>
            <Disclaimer />
          </Grid>
        </SectionLayout>
        <SectionLayout name="footer" backgroundColor="midnightBlue.main">
          <Grid item xs={12}>
            {!isSSR && (
              <React.Suspense fallback="">
                <Footer />
              </React.Suspense>
            )}
          </Grid>
        </SectionLayout>
      </Box>
    </PageContainer>
  )
}

PageWrapper.defaultProps = {
  trackingName: '',
  metaTags: undefined,
}

export default PageWrapper
