import { useMutation, useQuery } from '@apollo/client'
import React, { useState } from 'react'
import {
  AnyInputConfig,
  CloseIcon,
  GridForm,
  InputType,
  TrackedIconButton,
  useTracking,
} from '@flock/shared-ui'
import {
  AddressData,
  createBrokerHelper,
  formatCityStateZip,
  localStore,
  UserEventType,
} from '@flock/utils'
import { Box, Modal, Typography, useMediaQuery, useTheme } from '@mui/material'
import {
  LandingCompletedLeadSideEffectsDocument,
  LandingCreateBrokerDocument,
  LandingSearchBrokersDocument,
  LandingSearchLeadsDocument,
  LandingUpdateLeadDocument,
} from '@flock/flock-gql-server/src/__generated__/graphql'

import { User } from '../types'

type FormResult = {
  fullName: string
  phoneNumber: string
  email: string
  bedCount: string
  bathCount: string
  squareFootage: string
  propertyCondition: string
  mortgageAmount: string
  additionalInfo?: string
}

type AgentLeadGenFormProps = {
  prefillData: { [key: string]: string }
  address: AddressData
  leadUuid: string
  onSubmitDetails: (user: User) => void
  open: boolean
  onClose: () => void
}

const AgentLeadGenForm = (props: AgentLeadGenFormProps) => {
  const { address, prefillData, leadUuid, onSubmitDetails, open, onClose } =
    props

  const { track } = useTracking()
  const [loading, setLoading] = useState(false)
  const [showError, setShowError] = useState(false)

  const [updateLead] = useMutation(LandingUpdateLeadDocument)
  const [completedLeadSideEffects] = useMutation(
    LandingCompletedLeadSideEffectsDocument
  )
  const [createBroker] = useMutation(LandingCreateBrokerDocument)

  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))

  const { refetch: searchBrokers } = useQuery(LandingSearchBrokersDocument, {
    skip: true,
  })

  const onSubmit = async (result: FormResult, reset: any) => {
    setLoading(true)
    const {
      fullName,
      email,
      phoneNumber,
      bedCount,
      bathCount,
      squareFootage,
      propertyCondition,
      mortgageAmount,
      additionalInfo,
    } = result

    // Search for the broker by email. If it exists, get the UUID.
    // If it does not exist, create a new broker.
    const brokerName = fullName
    const brokerEmail = email
    const brokerPhone = phoneNumber
    let brokerUuid = ''
    try {
      const searchBrokersResult = await searchBrokers({
        input: {
          searchString: email,
        },
      })

      const { data } = searchBrokersResult
      const matchingBrokers = data?.searchBrokers?.brokers || []
      if (!matchingBrokers.length) {
        const brokerData = {
          phoneNumber,
          fullName,
          email,
        }
        const createBrokerResult = await createBrokerHelper(
          brokerData,
          createBroker
        )
        brokerUuid = createBrokerResult.data.createBroker.uuid
      } else {
        const matchingBroker = matchingBrokers[0]
        brokerUuid = matchingBroker.uuid
      }
    } catch (e) {
      console.error('Failed to find or create agent')
    }

    const resultToSubmit: any = {
      bedCount,
      bathCount,
      squareFootage,
      propertyCondition,
      mortgageAmount: mortgageAmount === '' ? undefined : mortgageAmount,
      additionalInfo,
      isOwner: 'false',
      brokerUuid,
      attribution: 'brokerLandingDirect',
    }

    const { streetNumber, streetAddress, city, state, zipcode } = address

    try {
      await updateLead({
        variables: {
          updateLeadInput: {
            leadUuid,
            fullName: brokerName,
            email: brokerEmail,
            phoneNumber: brokerPhone,
            brokerUuid,
            answers: JSON.stringify(resultToSubmit),

            addressStreet: streetAddress,
            addressCity: city,
            addressState: state,
            addressZip: zipcode,
            addressStreetNumber: streetNumber,
          },
        },
        refetchQueries: [LandingSearchLeadsDocument],
      })

      await completedLeadSideEffects({
        variables: {
          completedLeadSideEffectsInput: {
            leadUuid,
            sendWelcomeEmail: false,
          },
        },
      })

      track(
        'agent-lead-gen',
        {
          type: 'button',
          leadConversion: 'finished',
        },
        UserEventType.BUTTON_CLICK
      )

      reset()
      onSubmitDetails({
        fullName,
        email,
        phoneNumber,
      })

      localStore?.setItem('brokerName', fullName)
      localStore?.setItem('brokerEmail', email)
      localStore?.setItem('brokerPhone', phoneNumber)

      onClose()
    } catch (e) {
      setShowError(true)
    }
    setLoading(false)
  }

  const inputConfigs: AnyInputConfig[] = [
    {
      name: 'prompt',
      type: InputType.CustomComponent,
      props: {
        component: (
          <Box
            display="flex"
            flexDirection="column"
            gap="16"
            pb="16px"
            pt="32px"
          >
            <Typography variant="h3" pb="16px">
              Let us know your contact info and a few details about the property
              and we&apos;ll be in touch!
            </Typography>
            <Typography variant="h4">
              {address?.streetNumber} {address?.streetAddress}
              <br />
              {formatCityStateZip(address)}
            </Typography>
          </Box>
        ),
      },
    },
    {
      name: 'fullName',
      type: InputType.Text,
      required: true,
      props: {
        format: 'fullName',
        label: 'Your Full Name',
      },
    },
    {
      name: 'email',
      type: InputType.Text,
      required: true,
      props: {
        format: 'email',
        label: 'Your Email',
      },
    },
    {
      name: 'phoneNumber',
      type: InputType.Text,
      required: true,
      props: {
        format: 'phone',
        label: 'Your Phone Number',
      },
    },
    {
      name: 'optionalSection',
      type: InputType.Section,
      props: {
        title: 'Property Details (optional)',
        inputConfigs: [
          {
            name: 'bedCount',
            type: InputType.Text,
            gridItemProps: {
              xs: 12,
              sm: 4,
            },
            props: {
              label: 'Beds',
              'aria-label': 'Bed Count',
              format: 'number',
              placeholder: '0',
            },
          },
          {
            name: 'bathCount',
            type: InputType.Text,
            gridItemProps: {
              xs: 12,
              sm: 4,
            },
            props: {
              label: 'Baths',
              'aria-label': 'Bath Count',
              format: 'number',
              placeholder: '0',
            },
          },
          {
            name: 'squareFootage',
            type: InputType.Text,
            gridItemProps: {
              xs: 12,
              sm: 4,
            },
            props: {
              label: 'Square Footage',
              'aria-label': 'Square Footage',
              format: 'number',
              placeholder: '0',
            },
          },
          {
            name: 'propertyCondition',
            type: InputType.Dropdown,
            defaultValue: '4 - great',
            props: {
              label: 'Property Condition',
              options: [
                {
                  label: 'Near perfect',
                  value: '5 - pristine',
                },
                {
                  label: 'Normal wear and tear',
                  value: '4 - great',
                },
                {
                  label: 'Needs minor repairs',
                  value: '3 - average',
                },
                {
                  label: 'Needs major work',
                  value: '2 - below average',
                },
                {
                  label: 'Not habitable',
                  value: '1 - requires complete renovation',
                },
              ],
            },
          },
          {
            name: 'mortgageAmount',
            type: InputType.Text,
            props: {
              label: 'Outstanding mortgage (leave blank if unknown)',
              placeholder: '$0',
              format: 'dollars',
            },
          },
        ],
      },
    },
    {
      name: 'additionalInfo',
      type: InputType.Text,
      props: {
        type: 'text',
        label: 'Additional Info (Optional)',
        multiline: true,
        minRows: 4,
        placeholder: 'Enter any additional info about the property here.',
      },
    },
  ]
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        gap="32px"
        sx={{
          maxHeight: '75vh',
          overflowY: 'scroll',
          position: 'absolute' as 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'gray1.main',
          borderRadius: '24px',
          boxShadow: '0px 8px 24px 0px #45494D14',
          p: '32px',

          [theme.breakpoints.down('md')]: {
            top: 0,
            left: 0,
            maxHeight: 'unset',
            height: '100%',
            width: '100%',
            transform: 'unset',
            borderRadius: 'unset',
            pb: '0px',
          },
        }}
      >
        <TrackedIconButton
          onClick={onClose}
          sx={{ position: 'absolute', top: '32px', right: '32px' }}
        >
          <CloseIcon />
        </TrackedIconButton>
        <GridForm
          onSubmit={onSubmit}
          inputConfigs={inputConfigs}
          loading={loading}
          ctaBoxProps={{
            sx: {
              pb: isTablet ? '32px' : 0,
            },
          }}
          gridProps={{
            spacing: 3,
          }}
          formProps={{
            defaultValues: {
              ...prefillData,
              fullName: localStore?.getItem('brokerName') || '',
              email: localStore?.getItem('brokerEmail') || '',
              phoneNumber: localStore?.getItem('brokerPhone') || '',
            },
          }}
        />
        {showError && (
          <Typography variant="h3" color="error.main">
            An error occurred, please try again.
          </Typography>
        )}
      </Box>
    </Modal>
  )
}

export default AgentLeadGenForm
