import { useMutation, useQuery } from '@apollo/client'
import {
  Core_TransactionType,
  LandingCreateLeadDocument,
  LandingGetHomeDetailsDocument,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import {
  AddressData,
  AddressTextField,
  RightArrowIcon,
  TrackedButton,
} from '@flock/shared-ui'
import { createLeadHelper } from '@flock/utils'
import {
  Box,
  CircularProgress,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import React, { useState } from 'react'
import { GOOGLE_MAPS_API_KEY } from '../../constants'
import SectionLayout from '../SharedComponents/SectionLayout'
import AgentLeadGenForm from './AgentLeadGenForm'
import AgentSuccessModal from './AgentSuccessModal'

type AgentsHeroSectionProps = {
  headline?: string
  description?: string

  ctaTitle?: string
  ctaBody?: string
}

const AgentsHeroSection = (props: AgentsHeroSectionProps) => {
  const { headline, description, ctaTitle, ctaBody } = props
  const [addressData, setAddressData] = useState<AddressData | null>(null)
  const [showError, setShowError] = useState(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [leadUuid, setLeadUuid] = useState<string>()
  const [prefillData, setPrefillData] = useState<any>()
  const [leadGenFormOpen, setLeadGenFormOpen] = useState(false)
  const [addressFieldName, setAddressFieldName] = useState('addressField')

  const [successModalOpen, setSuccessModalOpen] = useState(false)

  const [createLead] = useMutation(LandingCreateLeadDocument)
  const { refetch: getHomeDetails } = useQuery(LandingGetHomeDetailsDocument, {
    // We skip the initial fetch since we want to call this endpoint on demand
    skip: true,
  })

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const onSubmitAddress = async () => {
    setLoading(true)
    try {
      if (addressData?.streetAddress) {
        const { streetNumber, streetAddress, city, state, zipcode } =
          addressData
        const addressInput = {
          streetAddress: `${streetNumber} ${streetAddress}`,
          city,
          state,
          zip: zipcode,
        }

        let newPrefillData: any = {}

        const { data: prefillResponse } = await getHomeDetails({
          homeDetailsInput: addressInput,
        })

        if (prefillResponse) {
          newPrefillData = {
            ...prefillResponse?.homeDetails?.homeDetails,
          }
          newPrefillData.bedCount = newPrefillData.bedrooms
          newPrefillData.bathCount = newPrefillData.fullBathrooms
          newPrefillData.prefillSquareFootage = newPrefillData.squareFootage
          newPrefillData.prefillBedCount = newPrefillData.bedrooms
          newPrefillData.prefillBathCount = newPrefillData.fullBathrooms
          newPrefillData.prefillHalfBathCount = newPrefillData.halfBathrooms
          newPrefillData.prefillBasement = newPrefillData.basement
          newPrefillData.prefillYearBuilt = newPrefillData.yearBuilt
          newPrefillData.prefillBuildingQualityScore =
            newPrefillData.buildingQualityScore
          newPrefillData.prefillBuildingConditionScore =
            newPrefillData.buildingConditionScore
        }

        const createLeadResponse = await createLeadHelper(
          {
            addressData,
            source: 'agents_page_onboarding',
            transactionType: Core_TransactionType.TransactionTypeBroker,
          },
          {},
          createLead
        )
        const newLeadUuid = createLeadResponse?.data.createLead.lead.uuid

        setLeadUuid(newLeadUuid)
        setPrefillData(newPrefillData)
        setLeadGenFormOpen(true)
      } else {
        setShowError(true)
      }
    } catch (e) {
      window.location.href = `https://form.jotform.com/232717575357162?address[addr_line1]=${addressData?.streetNumber}%20${addressData?.streetAddress}&address[city]=${addressData?.city}&address[state]=${addressData?.state}&address[postal]=${addressData?.zipcode}`
    }

    setLoading(false)
  }

  const onKeyDown = (event: any) => {
    if (event.keyCode === 13) {
      event.preventDefault()
      onSubmitAddress()
    }
  }

  const onSubmitDetails = () => {
    setSuccessModalOpen(true)
    // This is a hack to get the address field to reset
    if (addressFieldName === 'addressField') {
      setAddressFieldName('addressField2')
    } else {
      setAddressFieldName('addressField')
    }
    setAddressData(null)
  }

  return (
    <>
      <SectionLayout name="hero-section" backgroundColor="gray1.main">
        <Grid
          item
          xs={12}
          md={6}
          display="flex"
          flexDirection="column"
          gap={{ md: '32px', sm: '32px', xs: '16px' }}
        >
          <Typography variant="h2" color="moneyGreen.main">
            {headline}
          </Typography>
          <Typography variant="h4">{description}</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            display="flex"
            flexDirection="column"
            gap="16px"
            padding="24px"
            borderRadius="16px"
            boxShadow="0px 8px 24px 0px #45494D14"
            sx={{
              backgroundColor: '#ffffff',
            }}
          >
            <Typography variant="h3">{ctaTitle}</Typography>
            <Typography variant="p2">{ctaBody}</Typography>

            <AddressTextField
              name={addressFieldName}
              size={isMobile ? 'small' : 'large'}
              variant="outlined"
              placeholder={isMobile ? 'Address' : 'Enter address'}
              error={showError}
              helperText={showError ? 'Please enter a residential address' : ''}
              ctaContent={
                loading ? (
                  <CircularProgress size="24px" color="white" />
                ) : (
                  <RightArrowIcon width="24px" height="24px" color="white" />
                )
              }
              onKeyDown={onKeyDown}
              fullWidth
              onChange={(data: AddressData | null) => setAddressData(data)}
              // @ts-ignore
              onSubmit={!isMobile ? onSubmitAddress : null}
              googleMapsApiKey={GOOGLE_MAPS_API_KEY || ''}
            />
            {isMobile && (
              <TrackedButton onClick={onSubmitAddress}>Submit</TrackedButton>
            )}
          </Box>
        </Grid>
      </SectionLayout>
      <AgentLeadGenForm
        prefillData={prefillData as any}
        address={addressData as AddressData}
        leadUuid={leadUuid as string}
        onSubmitDetails={onSubmitDetails}
        open={leadGenFormOpen}
        onClose={() => setLeadGenFormOpen(false)}
      />
      <AgentSuccessModal
        open={successModalOpen}
        onClose={() => setSuccessModalOpen(false)}
      />
    </>
  )
}

export default AgentsHeroSection

AgentsHeroSection.defaultProps = {
  headline: `Your clients' vacation rentals. Your full commission.`,
  description: `Earn your commission while setting your client up for financial success and peace of mind. Exchange your clients' properties for shares in Overmoon's 721 Fund portfolio while avoiding the taxes of a typical sale.`,
  ctaTitle: 'Earning your commission has never been easier.',
  ctaBody:
    'Have a client in mind? Request a no-obligation property estimate for their property in a few seconds!',
}
