import React from 'react'
import { Typography } from '@mui/material'

const Disclaimer = () => (
  <Typography component="p" variant="disclaimer" color="disclaimer.main">
    * Important Notices The information provided herein is for informational
    purposes only and for persons who qualify as accredited investors. It should
    not be interpreted as professional financial, tax or investment advice. The
    information provided herein is intentionally general in nature. It is
    important not to solely rely on this information when making investment
    decisions. Overmoon cannot guarantee the accuracy, completeness, or
    reliability of any information presented here. Private securities
    transactions involve a high level of risk and are not be suitable for all
    investors. These investments are illiquid, may require a long holding
    period, and could result in the loss of the invested principal. There can be
    no guarantee that investors will receive distributions or recognize their
    desired tax or investment objectives. Projections and forward-looking
    statements are hypothetical and may not be realized. Persons interested in
    the Overmoon 721 Fund are urged to consult their own tax and financial
    advisors. Prior to making any investment decisions, prospective investors
    should thoroughly review all offering documents and carefully consider their
    individual circumstances and risk tolerance.
    <br />
    <br />
    The homes depicted on this page are not owned by the Overmoon 721 Fund, but
    are representative of the types of homes to be acquired by the Overmoon 721
    Fund.
  </Typography>
)

export default Disclaimer
