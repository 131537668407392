import React from 'react'
import { Typography, TypographyProps } from '@mui/material'

type NavSectionHeaderProps = TypographyProps & {
  color?: any
}

const NavSectionHeader = (props: NavSectionHeaderProps) => {
  const { color } = props
  return (
    <Typography
      sx={{
        color: color || 'green3.main',
        marginTop: {
          xs: '32px',
          md: 'inherit',
        },
      }}
      variant="h3"
      {...props}
    />
  )
}

export default NavSectionHeader

NavSectionHeader.defaultProps = {
  color: 'green3.main',
}
