import React from 'react'
import AgentsHeroSection from '../components/AgentsComponents/AgentsHeroSection'
import PageWrapper from '../components/SharedComponents/PageWrapper'

const pageTitle = 'Overmoon | Agents'
const pageDescription = `Your clients' rentals. Your full commission.`

export const Head = () => (
  <>
    <title>{pageTitle}</title>
    <meta title="title" property="og:title" content={pageTitle} />
    <meta
      name="description"
      property="og:description"
      content={pageDescription}
    />
  </>
)

const Agents = () => (
  <PageWrapper title={pageTitle} trackingName="agents">
    <AgentsHeroSection />
  </PageWrapper>
)

export default Agents
