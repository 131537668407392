import React from 'react'
import { CloseIcon, TrackedButton, TrackedIconButton } from '@flock/shared-ui'
import { Box, Modal, Typography, useTheme } from '@mui/material'

type AgentSuccessModalProps = {
  open: boolean
  onClose: () => void
}

const AgentSuccessModal = (props: AgentSuccessModalProps) => {
  const { open, onClose } = props
  const theme = useTheme()

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        gap="32px"
        sx={{
          maxHeight: '75vh',
          overflowY: 'scroll',
          position: 'absolute',
          width: '500px',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: '#ffffff',
          borderRadius: '24px',
          boxShadow: '0px 8px 24px 0px #45494D14',
          p: '32px',

          [theme.breakpoints.down('md')]: {
            top: 0,
            left: 0,
            maxHeight: 'unset',
            height: 'calc(100% - 32px)',
            width: 'calc(100% - 64px)',
            transform: 'unset',
            borderRadius: 'unset',
            pb: '0px',
          },
        }}
      >
        <TrackedIconButton
          onClick={onClose}
          sx={{ position: 'absolute', top: '32px', right: '32px' }}
        >
          <CloseIcon />
        </TrackedIconButton>

        <Typography variant="h3" pt="16px">
          Success!
        </Typography>
        <Typography variant="body1">
          Thank you for submitting this property, we will be in touch soon!
        </Typography>
        <TrackedButton onClick={onClose}>Submit another property</TrackedButton>
      </Box>
    </Modal>
  )
}

export default AgentSuccessModal
